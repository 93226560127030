import React from 'react'
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBFooter,
  MDBBtn,
  MDBIcon,
} from 'mdbreact'
import { Link } from 'gatsby'
import GatsbyImage from 'gatsby-image'

const Footer = () => {
  return (
    <MDBFooter className="font-small pt-0">
      <MDBContainer>
        <MDBRow className="pt-5 mb-3 text-center d-flex justify-content-center">
          <MDBCol md="2" className="b-4">
            <h6 className="title font-weight-bold">
              <Link to="/nominera">Nominera</Link>
            </h6>
          </MDBCol>

          <MDBCol md="2" className="b-4">
            <h6 className="title font-weight-bold">
              <a href="https://www.newst.se/">Newst</a>
            </h6>
          </MDBCol>
          <MDBCol md="2" className="b-4">
            <h6 className="title font-weight-bold">
              <a href="https://www.newst.se/kontakt">Kontakt</a>
            </h6>
          </MDBCol>
        </MDBRow>
        <hr className="rgba-white-light" style={{ margin: '0 15%' }} />
        <MDBRow className="d-flex text-center justify-content-center mb-md-0 mb-4">
          <MDBCol md="8" sm="12" className="mt-5">
            <p style={{ lineHeight: '1.7rem' }}>
              Snyggaste Kontoret är en tävling som drivs av marknadsplatsen
              Newst.se. Newst.se lanserades i oktober 2018 och bakom bolaget
              står SF Invest, Svenska Fastighetsbranschens
              Investeringsaktiebolag (publ), som ägs utav 15 st av branschens
              mest tongivande fastighetsägare och konsulter.
            </p>
          </MDBCol>
        </MDBRow>
        <hr
          className="clearfix d-md-none rgba-white-light"
          style={{ margin: '10% 15% 5%' }}
        />
        <MDBRow className="pb-3">
          <MDBCol md="12">
            <div className="mb-5 flex-center">
              <a href="https://www.facebook.com/newstsverige/" target="_blank">
                <MDBIcon
                  fab
                  icon="facebook-f"
                  size="lg"
                  className="mx-2 white-text mr-md-4"
                />
              </a>
              <a href="https://twitter.com/NewstSverige" target="_blank">
                <MDBIcon
                  fab
                  icon="twitter"
                  size="lg"
                  className="mx-2 white-text mr-md-4"
                />
              </a>
              <a href="https://www.linkedin.com/company/newst/" target="_blank">
                <MDBIcon
                  fab
                  icon="linkedin-in"
                  size="lg"
                  className="mx-2 white-text mr-md-4"
                />
              </a>
              <a href="https://www.instagram.com/newst.se/" target="_blank">
                <MDBIcon
                  fab
                  icon="instagram"
                  size="lg"
                  className="mx-2 white-text mr-md-4"
                />
              </a>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Newst
          {/*<a href="https://www.newst.se" alt="">
            {' '}
            Newst{' '}
  </a>*/}
        </MDBContainer>
      </div>
    </MDBFooter>
  )
}

export default Footer

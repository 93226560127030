import React, { Component } from 'react'
import Layout from '../components/layout'

import SEO from '../components/seo'

import { MDBRow, MDBCol, MDBMask, MDBView } from 'mdbreact'
import { graphql } from 'gatsby'

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        keywords={['Årets Snyggaste kontor']}
      />
      {/*<Carousel />
          <Intro />*/}
      <main>
        {/*<AboutSection />*/}
        <section id="cardSection mt-0">
          <div>
            {/*
                <Lightbox
                  image1={
                    post.frontmatter.image.childImageSharp.fluid.originalImg
                  }
                />
                */}
            <MDBRow className="mb-0">
              <MDBCol>
                <MDBView className="mb-4">
                  <img
                    src={
                      post.frontmatter.image.childImageSharp.fluid.originalImg
                    }
                    className="post-image"
                    alt=""
                  />
                  <MDBMask className="flex-center" overlay="black-light">
                    <h1 className="white-text display-3">
                      Årets kontor - {post.frontmatter.title}
                    </h1>
                  </MDBMask>
                </MDBView>
                {/**/}
              </MDBCol>
            </MDBRow>

            {/* <MDBRow>
                <MDBCol />

                <MDBCol size="4">
                  <h1 className="text-center">
                    Topp 125 - {post.frontmatter.title}
                  </h1>
                </MDBCol>
                <MDBCol />
             </MDBRow>*/}
            <MDBRow center>
             

              <MDBCol size="10" lg="5" center>
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
              </MDBCol>
              
                <MDBCol size="10" lg="2" center>
                  <h5>{post.frontmatter.title}</h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.fakta,
                    }}
                  />
                </MDBCol>
       
       
            </MDBRow>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
              originalImg
            }
          }
        }
        fakta
      }
    }
  }
`

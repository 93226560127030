import React from 'react'

const SvgComponent = () => (
  <svg
    width="50px"
    height="50px"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M50,25A25,25,0,1,1,25,0,25,25,0,0,1,50,25Z" fill="#ffff" />
    <path
      class="cls-2"
      d="M21.77,37.72V28h6.46v9.68H36.3V24.81h4.85L25,10.28,8.85,24.81H13.7V37.72Z"
      fill="#3c0472"
    />
  </svg>
)

export default SvgComponent
